import React from "react";
import { graphql } from "gatsby";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { Link as ScrollLink } from "react-scroll";

import Layout from "../components/Layout";

import nzxt from "../img/nzxt.webp";
import "@splidejs/react-splide/css/skyblue";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import QuotationSection from "../components/sections/QuotationSection";

import cpu_ico from "../img/ico/cpu-2.svg";
import mobo_ico from "../img/ico/mobo.svg";
import gpu_ico from "../img/ico/gpu.svg";

import hero_img from "../assets/img/hero/pc.png";
import gg from "../assets/img/hero/gg.png";

const pointers = [
  {
    title: "Customization expertise",
    description:
      "Tailor PC builds to meet individual needs, offering expert guidance on component selection for optimal performance.",
  },
  {
    title: "Quality assurance",
    description:
      "Ensure rigorous testing and quality control measures to guarantee reliability and satisfaction with every build.",
  },
  {
    title: "Performance optimization",
    description:
      "Fine-tune hardware configurations and software setups to maximize speed, efficiency, and gaming capabilities.",
  },
  {
    title: "Aesthetic appeal",
    description:
      "Offer customizable RGB lighting options and sleek case designs to enhance the visual appeal of each PC setup.",
  },
  {
    title: "Warranty and support",
    description:
      "Provide comprehensive warranty coverage and responsive customer support to address any issues or concerns promptly.",
  },
  {
    title: "Future-proofing",
    description:
      "Incorporate upgrade-friendly designs and compatibility considerations to support future expansions and technology advancements.",
  },
];

const IndexPage = ({ data }) => {
  const settings = {
    // slidesToShow: 2,
    // slidesToScroll: 1,
    dots: true,
    centerMode: true,
    focusOnSelect: true,
    centerPadding: "62px",
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const past_builds = data.past_builds.edges;

  const testimonials = data.testimonial.edges;

  let IndianRupee = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
  });

  return (
    <Layout>
      <section className="hero-section">
        <div className="hero-wrapper">
          <div className="row">
            <div className="col-md-8">
              <div className="hero-img">
                <img src={hero_img} alt="hero" />
              </div>
            </div>
            <div className="col-md-4 content-col">
              <div className="hero-ctnt">
                <h1>
                  We <span>Customize and Build PCs</span> to fit your needs!
                </h1>

                <p>Unlock Your Destiny: Forge your Ultimate Custom PC Build.</p>

                <ScrollLink
                  to="quotation-section"
                  spy={false}
                  duration={2000}
                  smooth={true}
                  className="btn btn-primary"
                >
                  Get your PC
                </ScrollLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features-section">
        <div className="container">
          <div className="features-wrapper">
            <div className="title-wrap">
              <div className="bg-text">Awesome</div>
              <h2>
                Take charge of your
                <br /> Phenomenal PC Setup.
              </h2>
            </div>

            <div className="row">
              {pointers &&
                pointers.map((pointer, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="feature-card">
                      <h2>{pointer.title}</h2>

                      <p>{pointer.description}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="container">
          <div className="about-wrapper row">
            <div className="col-md-6">
              <div className="title-wrap">
                <div className="bg-text">Custom</div>
                <h2>
                  Craft your vision, we
                  <br /> bring it to life.
                </h2>
              </div>

              <p className="mt-min-2">
                At Computer Hut, we're committed to making the computer
                purchasing and ownership experience enjoyable and efficient. Our
                goal is to ensure that your computer not only meets your needs
                but enhances your productivity without any setbacks. With
                consultants proficient in both software and hardware, we're
                equipped to comprehend the intricacies of your unique workflow,
                providing tailored solutions to optimize your efficiency and
                satisfaction.
              </p>

              <ScrollLink
                to="quotation-section"
                spy={false}
                duration={2000}
                smooth={true}
                className="btn btn-primary"
              >
                Get Started
              </ScrollLink>
            </div>

            <div className="col-md-6">
              <div className="about-img">
                <img src={gg} alt="About" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="process-section">
        <div className="container">
          <div className="process-wrapper">
            <div className="title-wrap">
              <div className="bg-text">Process</div>
              <h2>
                How we craft
                <br /> your PC.
              </h2>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="feature-card">
                  <h2>Step 01</h2>

                  <h4>
                    <span>Consultation & Quotation</span>
                  </h4>

                  <p>
                    Collaboratively discuss gaming requirements and budget to
                    receive a detailed pricing estimate.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="feature-card">
                  <h2>Step 02</h2>

                  <h4>
                    <span>Customization & Confirmation</span>
                  </h4>

                  <p>
                    Tailor the PC specs to preferences and finalize the order
                    details.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="feature-card">
                  <h2>Step 03</h2>

                  <h4>
                    <span>Build & Testing</span>
                  </h4>

                  <p>
                    Expertly assemble the components, rigorously test the
                    system, and ensure it meets performance standards before
                    shipping.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <QuotationSection />

      <section className="previous-builds-section" id="past-builds-section">
        <div className="container">
          <div className="previous-builds-wrapper">
            <div className="title-wrap">
              <div className="bg-text">Builds</div>
              <h2>
                Previous PC builds that last our
                <br /> customers absolutely love!
              </h2>
            </div>

            <div className="prev-builds-slider">
              <Splide
                aria-label="My Favorite Images"
                options={{
                  rewind: true,
                  gap: "1rem",
                  type: "loop",
                  pagination: false,
                  interval: 6000,
                  speed: 1000,
                  autoplay: "play",
                  perPage: 2,
                  breakpoints: {
                    768: {
                      perPage: 1,
                    },
                  },
                }}
                hasTrack={false}
              >
                <SplideTrack>
                  {past_builds.map((build, index) => (
                    <SplideSlide key={index.toString()}>
                      <div className="prev-build-card">
                        <div className="img">
                          <img src={build.node.frontmatter.image} alt="build" />
                        </div>
                        <div className="prev-build-details">
                          <div className="title-wp">
                            <p className="tt">{build.node.frontmatter.title}</p>
                            <p className="pp">
                              {IndianRupee.format(
                                build.node.frontmatter.budget
                              )}
                            </p>
                          </div>
                          <div className="specs-wp">
                            <div className="spec">
                              <span className="ico">
                                <img src={cpu_ico} alt="cpu_ico" />
                              </span>
                              <p>{build.node.frontmatter.cpu}</p>
                            </div>
                            <div className="spec">
                              <span className="ico">
                                <img src={mobo_ico} alt="mobo_ico" />
                              </span>
                              <p>{build.node.frontmatter.motherboard}</p>
                            </div>
                            <div className="spec">
                              <span className="ico">
                                <img src={gpu_ico} alt="gpu_ico" />
                              </span>
                              <p>{build.node.frontmatter.gpu}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SplideSlide>
                  ))}
                </SplideTrack>
                <div className="splide__progress">
                  <div className="splide__progress__bar"></div>
                </div>
              </Splide>
            </div>
          </div>
        </div>
        <div className="bg"></div>
      </section>

      <section className="testimonials-section" id="testimonials-section">
        <div className="container no-padding">
          <div className="previous-builds-wrapper">
            <div className="title-wrap">
              <div className="bg-text">Testimonials</div>
              <h2>
                Hear what our satisfied tech
                <br /> conquerors have to say!
              </h2>
            </div>
          </div>
          <Slider {...settings} className="slider-nav">
            {testimonials.map((testimonial, index) => (
              <div className="sl-feature-card" key={testimonial.node.id}>
                <div className="sl-feature-card-wp">
                  <div className="img-wrp">
                    <img src={testimonial.node.frontmatter.image} alt="img" />
                  </div>
                  <div className="content review">
                    <h5>{testimonial.node.frontmatter.title}</h5>
                    <p>{testimonial.node.frontmatter.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    past_builds: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "past-build" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            budget
            image
            cpu
            motherboard
            gpu
          }
        }
      }
    }
    testimonial: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "testimonial" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            image
          }
        }
      }
    }
  }
`;
