import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Controller, useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import clone from "just-clone";
import Select from "react-select";

import intel from "../../img/platform/intel.svg";
import amd from "../../img/platform/amd.svg";

import logo_blue from "../../img/logo-blue.png";

import plt_ico from "../../img/ico/cpu.svg";
import cpu_ico from "../../img/ico/cpu-2.svg";
import mobo_ico from "../../img/ico/mobo.svg";
import gpu_ico from "../../img/ico/gpu.svg";
import ssd_ico from "../../img/ico/ssd.svg";
import ssd_ico_2 from "../../img/ico/ssd-2.svg";
import psu_ico from "../../img/ico/psu.svg";
import ram_ico from "../../img/ico/ram.svg";
import case_ico from "../../img/ico/case.svg";
import cooler_ico from "../../img/ico/cooler.svg";
import mn_ico from "../../img/ico/monitor.svg";
import km_ico from "../../img/ico/km.svg";
import hd_ico from "../../img/ico/headphone.svg";
import ups_ico from "../../img/ico/ups.svg";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { format } from "date-fns";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { ORDER_STATUS } from "../../constants/ORDER_STATUS";

const PLATFORM = {
  INTEL: "Intel",
  AMD: "AMD",
};

const $ORDER_STATUS = ORDER_STATUS;

const QuotationSection = () => {
  const [global_tdp, setGlobalTdp] = useState(0);
  const [platform, setPlatform] = useState("intel");

  var [data_set, setDataSet] = useState([]);

  var normalised_cpu = [];
  const [cpuList, setCpuList] = useState([]);

  /**
   * * motherboard List
   */
  var normalised_mb = [];
  const [motherboardList, setMotherboardList] = useState([]);

  /**
   * * ram List
   */
  const [ramList, setRamList] = useState([]);

  /**
   * * graphics_card List
   */
  const [gcList, setGcList] = useState([]);

  /**
   * * primary_storage List
   */
  const [primaryStorageList, setPrimaryStorageList] = useState([]);

  /**
   * * secondary_storage List
   */
  const [secondaryStorageList, setSecondaryStorageList] = useState([]);

  /**
   * * cabinet List
   */
  const [cabinetList, setCabinetList] = useState([]);

  /**
   * * cpu_cooler List
   */
  const [cpuCoolerList, setCpuCoolerList] = useState([]);

  /**
   * * psu List
   */
  const [psuList, setPsuList] = useState([]);

  /**
   * * monitor List
   */
  const [monitorList, setMonitorList] = useState([]);

  /**
   * * km List
   */
  const [kmList, setKmList] = useState([]);

  /**
   * * accessroies List
   */
  const [accrList, setAccrList] = useState([]);

  /**
   * * os List
   */
  const [osList, setOsList] = useState([]);

  const YupForm = Yup.object().shape({
    cpu: Yup.string().required("CPU is required"),
    motherboard: Yup.string().required("Motherboard is required"),
    ram: Yup.string().required("RAM is required"),
    graphicsCard: Yup.string().required("Graphics Card is required"),
    cpuCooler: Yup.string().required("CPU Cooler is required"),
    primaryStorage: Yup.string().required("Primary storage is required"),
    secondaryStorage: Yup.string().required("Secondary storage is required"),
    case: Yup.string().required("Computer case is required"),
    psu: Yup.string().required("Power supply is required"),

    monitor: Yup.string().required("Monitor is required"),
    km: Yup.string().required("Keyboard & Mouse is required"),
    headphone: Yup.string().required("Accessory is required"),
    ups: Yup.string().required("OS is required"),

    full_name: Yup.string().required("Full name is required"),
    phone_number: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{10}$/),

    email: Yup.string().required("Email is required").email(),
  });

  const resolverForm = { resolver: yupResolver(YupForm) };

  const { register, handleSubmit, reset, formState, control } =
    useForm(resolverForm);
  const { errors } = formState;

  const {
    field: { value: cpuValue, onChange: cpuOnChange, ...restCPUField },
  } = useController({ name: "cpu", control });

  const {
    field: {
      value: motherboardValue,
      onChange: motherboardOnChange,
      ...restMotherboardField
    },
  } = useController({ name: "motherboard", control });

  const {
    field: { value: ramValue, onChange: ramOnChange, ...restRamField },
  } = useController({ name: "ram", control });

  const {
    field: { value: ramQtyValue, onChange: ramQtyOnChange, ...restQtyRamField },
  } = useController({ name: "ram_qty", control });

  const {
    field: {
      value: graphicsCardValue,
      onChange: graphicsCardOnChange,
      ...restGraphicsCardField
    },
  } = useController({ name: "graphicsCard", control });

  const {
    field: {
      value: cpuCoolerValue,
      onChange: cpuCoolerOnChange,
      ...restCpuCoolerField
    },
  } = useController({ name: "cpuCooler", control });

  const {
    field: {
      value: primaryStorageValue,
      onChange: primaryStorageOnChange,
      ...restPrimaryStorageField
    },
  } = useController({ name: "primaryStorage", control });

  const {
    field: {
      value: primaryStorageQtyValue,
      onChange: primaryStorageQtyOnChange,
      ...restPrimaryStorageQtyField
    },
  } = useController({ name: "primaryStorage_qty", control });

  const {
    field: {
      value: secondaryStorageValue,
      onChange: secondaryStorageOnChange,
      ...restSecondaryStorageField
    },
  } = useController({ name: "secondaryStorage", control });

  const {
    field: { value: caseValue, onChange: caseOnChange, ...restCaseField },
  } = useController({ name: "case", control });

  const {
    field: { value: psuValue, onChange: psuOnChange, ...restPsuStorageField },
  } = useController({ name: "psu", control });

  const {
    field: {
      value: monitorValue,
      onChange: monitorOnChange,
      ...restMonitorStorageField
    },
  } = useController({ name: "monitor", control });

  const {
    field: {
      value: monitorQtyValue,
      onChange: monitorQtyOnChange,
      ...monitorQtyRamField
    },
  } = useController({ name: "monitor_qty", control });

  const {
    field: { value: kmValue, onChange: kmOnChange, ...restKmStorageField },
  } = useController({ name: "km", control });

  const {
    field: {
      value: headphoneValue,
      onChange: headphoneOnChange,
      ...restHeadphoneStorageField
    },
  } = useController({ name: "headphone", control });

  const {
    field: { value: upsValue, onChange: upsOnChange, ...restUPSStorageField },
  } = useController({ name: "ups", control });

  const resetForm = () => {
    reset({
      platform: null,
      cpu: null,
      motherboard: null,
      ram: null,
      ram_qty: null,
      graphicsCard: null,
      cpuCooler: null,
      primaryStorage: null,
      primaryStorage_qty: null,
      secondaryStorage: null,
      case: null,
      psu: null,

      monitor: null,
      monitor_qty: null,
      km: null,
      headphone: null,
      ups: null,

      full_name: null,
      phone_number: null,
      email: null,
    });
    setGlobalTdp(0);
  };

  const onPlatFormChange = (platform) => {
    normalised_cpu = [];
    normalised_mb = [];

    data_set.forEach((item) => {
      switch (item.product_category.name) {
        case "Processor": {
          normalised_cpu.push(normaliseProductObject(item));
          break;
        }
        case "Motherboard": {
          normalised_mb.push(normaliseProductObject(item));
          break;
        }
      }
    });

    setCpuList(normalised_cpu.filter((item) => item.platform === platform));

    setMotherboardList(
      normalised_mb.filter((item) => item.platform === platform)
    );
  };

  const [quotation, setQuotation] = useState(null);

  const onQuotationFormSubmit = (data) => {
    const $data = {
      name: data.full_name,
      phone: "+91" + data.phone_number,
      email: data.email,
      cpu: parseInt(data.cpu),
      motherboard: parseInt(data.motherboard),
      ram: parseInt(data.ram),
      ssd: parseInt(data.primaryStorage),
      secondary_storage: parseInt(data.secondaryStorage),
      cooler: parseInt(data.cpuCooler),
      gpu: parseInt(data.graphicsCard),
      power_supply: parseInt(data.psu),
      cabinet: parseInt(data.case),
      monitor: parseInt(data.monitor),
      km: parseInt(data.km),
      accr: parseInt(data.headphone),
      os: parseInt(data.ups),
      ram_qty: data.ram_qty,
      primary_storage_qty: data.primaryStorage_qty,
      secondary_storage_qty: 1,
      monitor_qty: data.monitor_qty,
    };

    var grand_total = 0;

    /**
     * consume POST api and handle response accordingly
     */
    axios
      .post("https://api.computerhut.club/api/v1/quotation", $data)
      .then((response) => {
        if (
          response.status === 201 ||
          response.status === 200 ||
          response.status === 202
        ) {
          const quotation_data = {
            id: response.data.data.id,
            platform: data.platform != "intel" ? "amd" : "intel",
            cpu: cpuList.find((c) => c.value === $data.cpu),
            motherboard: motherboardList.find(
              (c) => c.value === $data.motherboard
            ),
            ram: ramList.find((c) => c.value === $data.ram),
            ram_qty: $data.ram_qty ? $data.ram_qty : 1,
            graphicsCard: gcList.find((c) => c.value === $data.gpu),
            cpuCooler: cpuCoolerList.find((c) => c.value === $data.cooler),
            primaryStorage: primaryStorageList.find(
              (c) => c.value === $data.ssd
            ),
            secondaryStorage: secondaryStorageList.find(
              (c) => c.value === $data.secondary_storage
            ),
            primaryStorage_qty: $data.primary_storage_qty,
            case: cabinetList.find((c) => c.value === $data.cabinet),
            psu: psuList.find((c) => c.value === $data.power_supply),

            monitor: monitorList.find((c) => c.value === $data.monitor),
            monitor_qty: $data.monitor_qty ? $data.monitor_qty : 1,

            km: kmList.find((c) => c.value === $data.km),
            headphone: accrList.find((c) => c.value === $data.accr),
            ups: osList.find((c) => c.value === $data.os),

            full_name: data.full_name,
            phone_number: data.phone_number,
            email: data.email,
          };

          grand_total =
            quotation_data.cpu.price +
            quotation_data.motherboard.price +
            quotation_data.ram.price * quotation_data.ram_qty +
            quotation_data.graphicsCard.price +
            quotation_data.cpuCooler.price +
            quotation_data.primaryStorage.price *
            quotation_data.primaryStorage_qty +
            quotation_data.secondaryStorage.price +
            quotation_data.case.price +
            quotation_data.psu.price;

          if (quotation_data.monitor) {
            grand_total +=
              quotation_data.monitor.price * quotation_data.monitor_qty;
          }

          if (quotation_data.km) {
            grand_total += quotation_data.km.price;
          }

          if (quotation_data.headphone) {
            grand_total += quotation_data.headphone.price;
          }

          if (quotation_data.ups) {
            grand_total += quotation_data.ups.price;
          }

          quotation_data.total = grand_total;

          setQuotation(quotation_data);

          resetForm();

          axios
            .post(
              "https://api.computerhut.club/api/v1/quotation/send-to-whatsapp",
              {
                quotation_id: response.data.data.id,
              }
            )
            .then((response) => {
              if (response.status === 200) {
                console.log("Whatsapp message sent successfully!!");
              }
            })
            .catch((e) => {
              console.log(e);
              console.error("Error in sending whatsapp message!!");
            });
        }
      })
      .catch((e) => {
        console.log(e);
        console.error("Error in formspark!!");
      });
  };

  useEffect(() => {
    axios
      .get("https://api.computerhut.club/api/v1/product/front")
      .then((prod_response) => {
        if (prod_response.status === 200) {
          setDataSet(prod_response.data.data);

          prod_response.data.data.forEach((item) => {
            switch (item.product_category.name) {
              case "Processor": {
                normalised_cpu.push(normaliseProductObject(item));
                break;
              }
              case "Motherboard": {
                normalised_mb.push(normaliseProductObject(item));
                break;
              }
              case "RAM": {
                setRamList((ramList) => [
                  ...ramList,
                  normaliseProductObject(item),
                ]);
                break;
              }
              case "SSD (Primary Storage)": {
                setPrimaryStorageList((primaryStorageList) => [
                  ...primaryStorageList,
                  normaliseProductObject(item),
                ]);
                break;
              }
              case "Secondary Storage": {
                setSecondaryStorageList((secondaryStorageList) => [
                  ...secondaryStorageList,
                  normaliseProductObject(item),
                ]);
                break;
              }
              case "CPU Cooler": {
                setCpuCoolerList((cpuCoolerList) => [
                  ...cpuCoolerList,
                  normaliseProductObject(item),
                ]);
                break;
              }
              case "Graphics Card": {
                setGcList((gcList) => [
                  ...gcList,
                  normaliseProductObject(item),
                ]);
                break;
              }
              case "Power Supply": {
                setPsuList((psuList) => [
                  ...psuList,
                  normaliseProductObject(item),
                ]);
                break;
              }
              case "Cabinet": {
                setCabinetList((cabinetList) => [
                  ...cabinetList,
                  normaliseProductObject(item),
                ]);
                break;
              }
              case "Monitor": {
                setMonitorList((monitorList) => [
                  ...monitorList,
                  normaliseProductObject(item),
                ]);
                break;
              }
              case "Keyboard & Mouse": {
                setKmList((kmList) => [
                  ...kmList,
                  normaliseProductObject(item),
                ]);
                break;
              }
              case "Accessories": {
                setAccrList((accrList) => [
                  ...accrList,
                  normaliseProductObject(item),
                ]);
                break;
              }
              case "OS": {
                setOsList((osList) => [
                  ...osList,
                  normaliseProductObject(item),
                ]);
                break;
              }
            }
          });

          setCpuList(
            normalised_cpu.filter((item) => item.platform === platform)
          );

          setMotherboardList(
            normalised_mb.filter((item) => item.platform === platform)
          );
        }
      })
      .catch((e) => {
        console.log(e);
        console.error("Error in formspark!!");
      });

      // default value for quantities of components
      reset({
        ram_qty: 1,
        primaryStorage_qty: 1,
        monitor_qty: 1,
      });
  }, []);

  const normaliseProductObject = (product) => {
    return {
      value: product.id,
      label: product.name,
      price: product.price,
      platform: product.cpu_brand ? product.cpu_brand.name.toLowerCase() : "",
    };
  };

  let IndianRupee = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
  });

  var componentRef = useRef();

  const qty = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
  ];

  const [template, setTemplate] = React.useState(null);

  const handleShare = () => {
    const html = document.getElementById("html-template");

    html2canvas(html).then((canvas) => {
      canvas.getContext("2d");

      const imgData = canvas.toDataURL("image/png");

      var PDF_Width = 29.7;
      var PDF_Height = 21;
      var top_left_margin = 0;

      var doc = new jsPDF("p", "cm", [PDF_Height, PDF_Width], true);

      doc.addImage(
        imgData,
        "PNG",
        top_left_margin,
        top_left_margin,
        21,
        29.7,
        undefined,
        "FAST"
      );

      doc.save(`quotation-cp-hut.pdf`);
    });
  };

  const createOrder = () => {
    axios
      .post("https://api.computerhut.club/api/v1/orders/create-order/", {
        quotation_id: quotation.id,
      })
      .then((response) => {
        if (
          response.status === 201 ||
          response.status === 200 ||
          response.status === 202
        ) {
          payWithRazor(
            response.data.data.razorpayOrder.id,
            response.data.data.razorpayOrder.amount,
            response.data.data.newOrder.id
          );
        }
      })
      .catch((e) => {
        // e.response.data.data.status ===
        if (
          e.response.data.data.status === $ORDER_STATUS.CONFIRMED ||
          e.response.data.data.status === $ORDER_STATUS.FULLFILLED ||
          e.response.data.data.status === $ORDER_STATUS.CANCELLED
        ) {
          window.location.href = `/order-confirmation?order_id=${e.response.data.data.id}`;
        } else {
          axios
            .post("https://api.computerhut.club/api/v1/orders/retry-payment/", {
              order_id: e.response.data.data.id,
            })
            .then((response) => {
              if (
                response.status === 201 ||
                response.status === 200 ||
                response.status === 202
              ) {
                payWithRazor(
                  response.data.data.razorpayOrder.id,
                  response.data.data.razorpayOrder.amount,
                  e.response.data.data.id
                );
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
  };

  const payWithRazor = (order_id, amount, db_order_id) => {
    const options = {
      key: "rzp_live_gwBL9QKq7s5mq0",
      amount: amount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: "INR",
      name: "Computer Hut", // company name or product name
      description: "", // product description
      image: "", // company logo or product image
      order_id: order_id, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: "#0085ff",
      },
    };

    options.handler = (response, error) => {
      options.response = response;

      // handle the case when user closes the form while transaction is in progress
      axios
        .post("https://api.computerhut.club/api/v1/orders/payment-success/", {
          id: db_order_id,
          order_id: response.razorpay_order_id,
          payment_id: response.razorpay_payment_id,
          signature: response.razorpay_signature,
        })
        .then((response) => {
          if (response.status === 200) {
            // redirect to order-confirm page and add order id to the url
            window.location.href = `/order-confirmation?order_id=${db_order_id}`;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    options.modal.ondismiss = () => {
      // handle the case when user closes the form while transaction is in progress
      console.log("Transaction cancelled.");
    };

    const rzp = new window.Razorpay(options);

    rzp.open();
  };

  return (
    <section className="quotation-section" id="quotation-section">
      <div className="container">
        <div className="quotation-wrapper">
          <div className="title-wrap">
            <div className="bg-text">Quotation</div>
            <h2>
              Build your dream PC with us. <br /> Get a quotation for your PC.
            </h2>
            <p>
              Get a quotation for your dream PC. Fill the form below and get a
              quotation for your PC.
              <br/>
              <br/>
              ***Note: Select "None" if you don't want to add any component.
            </p>
          </div>

          <div className="quotation-builder">
            <form
              className="row"
              onSubmit={handleSubmit((data) => {
                onQuotationFormSubmit(data);
              })}
            >
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <div className="radio-gp">
                    <div
                      className={
                        platform == "intel" ? "rd-button selected" : "rd-button"
                      }
                    >
                      <input
                        {...register("platform")}
                        type="radio"
                        value="intel"
                        id="intel"
                        name="platform"
                        defaultChecked
                        onChange={(e) => {
                          resetForm();
                          onPlatFormChange("intel");
                          setPlatform("intel");
                        }}
                      />
                      <label className="label" htmlFor="intel">
                        <img src={intel} arc="intel" />
                      </label>
                    </div>

                    <div
                      className={
                        platform == "amd" ? "rd-button selected" : "rd-button"
                      }
                    >
                      <input
                        {...register("platform")}
                        type="radio"
                        value="amd"
                        id="amd"
                        name="platform"
                        onChange={(e) => {
                          resetForm();
                          onPlatFormChange("amd");
                          setPlatform("amd");
                        }}
                      />
                      <label className="label" htmlFor="amd">
                        <img src={amd} arc="amd" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <label>Your CPU</label>
                  <Select
                    placeholder="Search your new CPU..."
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={cpuList}
                    isClearable={true}
                    formatOptionLabel={(item) => (
                      <div className="select-option">
                        <div className="details">
                          <h6>{item.label}</h6>
                          <p>{IndianRupee.format(item.price)}</p>
                        </div>
                      </div>
                    )}
                    onChange={(option) => {
                      cpuOnChange(option ? option.value : option);
                    }}
                    value={
                      cpuValue
                        ? cpuList.find((x) => x.value === cpuValue)
                        : cpuValue
                    }
                    {...restCPUField}
                  />
                  <div className="invalid-feedback">{errors.cpu?.message}</div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <label>Motherboard</label>
                  <Select
                    placeholder="Search motherboard..."
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={motherboardList}
                    isClearable={true}
                    formatOptionLabel={(item) => (
                      <div className="select-option">
                        <div className="details">
                          <h6>{item.label}</h6>
                          <p>{IndianRupee.format(item.price)}</p>
                        </div>
                      </div>
                    )}
                    {...restMotherboardField}
                    onChange={(option) => {
                      // onMotherboardChange(option);
                      motherboardOnChange(option ? option.value : option);
                    }}
                    value={
                      motherboardValue
                        ? motherboardList.find(
                            (x) => x.value === motherboardValue
                          )
                        : motherboardValue
                    }
                  />
                  <div className="invalid-feedback">
                    {errors.motherboard?.message}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <label>RAM</label>
                  <div className="qty-ip">
                    <Select
                      placeholder="Search ram..."
                      className="react-select-container item-sl"
                      classNamePrefix="react-select"
                      options={ramList}
                      isClearable={true}
                      formatOptionLabel={(item) => (
                        <div className="select-option">
                          <div className="details">
                            <h6>{item.label}</h6>
                            <p>{IndianRupee.format(item.price)}</p>
                          </div>
                        </div>
                      )}
                      {...restRamField}
                      onChange={(option) => {
                        ramOnChange(option ? option.value : option);
                      }}
                      value={
                        ramValue
                          ? ramList.find((x) => x.value === ramValue)
                          : ramValue
                      }
                    />
                    <Select
                      className="react-select-container qty-sl"
                      classNamePrefix="react-select"
                      placeholder="qty."
                      isClearable={false}
                      isSearchable={false}
                      options={qty}
                      {...restQtyRamField}
                      name="ram_qty"
                      onChange={(qt) => {
                        ramQtyOnChange(qt ? qt.value : qt);
                      }}
                      value={
                        ramQtyValue
                          ? qty.find((l) => l.value === ramQtyValue)
                          : ramQtyValue
                      }
                    />
                  </div>
                  <div className="invalid-feedback">{errors.ram?.message}</div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <label>Graphics Card</label>
                  <Select
                    placeholder="Search graphics card..."
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={gcList}
                    isClearable={true}
                    formatOptionLabel={(item) => (
                      <div className="select-option">
                        <div className="details">
                          <h6>{item.label}</h6>
                          <p>{IndianRupee.format(item.price)}</p>
                        </div>
                      </div>
                    )}
                    {...restGraphicsCardField}
                    onChange={(option) =>
                      graphicsCardOnChange(option ? option.value : option)
                    }
                    value={
                      graphicsCardValue
                        ? gcList.find((x) => x.value === graphicsCardValue)
                        : graphicsCardValue
                    }
                  />
                  <div className="invalid-feedback">
                    {errors.graphicsCard?.message}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <label>CPU Cooler</label>
                  <Select
                    placeholder="Search cpu cooler..."
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={cpuCoolerList}
                    isClearable={true}
                    formatOptionLabel={(item) => (
                      <div className="select-option">
                        <div className="details">
                          <h6>{item.label}</h6>
                          <p>{IndianRupee.format(item.price)}</p>
                        </div>
                      </div>
                    )}
                    {...restCpuCoolerField}
                    onChange={(option) =>
                      cpuCoolerOnChange(option ? option.value : option)
                    }
                    value={
                      cpuCoolerValue
                        ? cpuCoolerList.find((x) => x.value === cpuCoolerValue)
                        : cpuCoolerValue
                    }
                  />
                  <div className="invalid-feedback">
                    {errors.cpuCooler?.message}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <label>Primary Storage</label>
                  <div className="qty-ip">
                    <Select
                      placeholder="Search primary storage..."
                      className="react-select-container item-sl"
                      classNamePrefix="react-select"
                      options={primaryStorageList}
                      isClearable={true}
                      formatOptionLabel={(item) => (
                        <div className="select-option">
                          <div className="details">
                            <h6>{item.label}</h6>
                            <p>{IndianRupee.format(item.price)}</p>
                          </div>
                        </div>
                      )}
                      {...restPrimaryStorageField}
                      onChange={(option) =>
                        primaryStorageOnChange(option ? option.value : option)
                      }
                      value={
                        primaryStorageValue
                          ? primaryStorageList.find(
                              (x) => x.value === primaryStorageValue
                            )
                          : primaryStorageValue
                      }
                    />
                    <Select
                      className="react-select-container qty-sl"
                      classNamePrefix="react-select"
                      placeholder="qty."
                      isClearable={false}
                      isSearchable={false}
                      options={qty}
                      {...restPrimaryStorageQtyField}
                      onChange={(option) => {
                        primaryStorageQtyOnChange(
                          option ? option.value : option
                        );
                      }}
                      value={
                        primaryStorageQtyValue
                          ? qty.find((x) => x.value === primaryStorageQtyValue)
                          : primaryStorageQtyValue
                      }
                    />
                  </div>
                  <div className="invalid-feedback">
                    {errors.primaryStorage?.message}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <label>Secondary Storage</label>
                  <Select
                    placeholder="Search secondary storage..."
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={secondaryStorageList}
                    isClearable={true}
                    formatOptionLabel={(item) => (
                      <div className="select-option">
                        <div className="details">
                          <h6>{item.label}</h6>
                          <p>{IndianRupee.format(item.price)}</p>
                        </div>
                      </div>
                    )}
                    {...restSecondaryStorageField}
                    onChange={(option) =>
                      secondaryStorageOnChange(option ? option.value : option)
                    }
                    value={
                      secondaryStorageValue
                        ? secondaryStorageList.find(
                            (x) => x.value === secondaryStorageValue
                          )
                        : secondaryStorageValue
                    }
                  />
                  <div className="invalid-feedback">
                    {errors.secondaryStorage?.message}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <label>Computer Case</label>
                  <Select
                    placeholder="Search case..."
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={cabinetList}
                    isClearable={true}
                    formatOptionLabel={(item) => (
                      <div className="select-option">
                        <div className="details">
                          <h6>{item.label}</h6>
                          <p>{IndianRupee.format(item.price)}</p>
                        </div>
                      </div>
                    )}
                    {...restCaseField}
                    onChange={(option) =>
                      caseOnChange(option ? option.value : option)
                    }
                    value={
                      caseValue
                        ? cabinetList.find((x) => x.value === caseValue)
                        : caseValue
                    }
                  />
                  <div className="invalid-feedback">{errors.case?.message}</div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <label>Power Supply Unit (PSU)</label>
                  <Select
                    placeholder="Search psu..."
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={psuList}
                    isClearable={true}
                    formatOptionLabel={(item) => (
                      <div className="select-option">
                        <div className="details">
                          <h6>{item.label}</h6>
                          <p>{IndianRupee.format(item.price)}</p>
                        </div>
                      </div>
                    )}
                    {...restPsuStorageField}
                    onChange={(option) =>
                      psuOnChange(option ? option.value : option)
                    }
                    value={
                      psuValue
                        ? psuList.find((x) => x.value === psuValue)
                        : psuValue
                    }
                  />
                  <div className="invalid-feedback">{errors.psu?.message}</div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12"></div>

              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <h6 className="ttl">Add Ons</h6>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <label>Monitor</label>
                  <div className="qty-ip">
                    <Select
                      placeholder="Search monitor..."
                      className="react-select-container item-sl"
                      classNamePrefix="react-select"
                      options={monitorList}
                      isClearable={true}
                      formatOptionLabel={(item) => (
                        <div className="select-option">
                          <div className="details">
                            <h6>{item.label}</h6>
                            <p>{IndianRupee.format(item.price)}</p>
                          </div>
                        </div>
                      )}
                      {...restMonitorStorageField}
                      onChange={(option) =>
                        monitorOnChange(option ? option.value : option)
                      }
                      value={
                        monitorValue
                          ? monitorList.find((x) => x.value === monitorValue)
                          : monitorValue
                      }
                    />
                    <Select
                      className="react-select-container qty-sl"
                      classNamePrefix="react-select"
                      placeholder="qty."
                      isClearable={false}
                      isSearchable={false}
                      options={qty}
                      {...monitorQtyRamField}
                      onChange={(option) => {
                        monitorQtyOnChange(option ? option.value : option);
                      }}
                      value={
                        monitorQtyValue
                          ? qty.find((x) => x.value === monitorQtyValue)
                          : monitorQtyValue
                      }
                    />
                  </div>
                  <div className="invalid-feedback">
                    {errors.monitor?.message}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <label>Keyboard & Mouse</label>
                  <Select
                    placeholder="Search keyboard & mouse..."
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={kmList}
                    isClearable={true}
                    formatOptionLabel={(item) => (
                      <div className="select-option">
                        <div className="details">
                          <h6>{item.label}</h6>
                          <p>{IndianRupee.format(item.price)}</p>
                        </div>
                      </div>
                    )}
                    {...restKmStorageField}
                    onChange={(option) =>
                      kmOnChange(option ? option.value : option)
                    }
                    value={
                      kmValue
                        ? kmList.find((x) => x.value === kmValue)
                        : kmValue
                    }
                  />
                  <div className="invalid-feedback">
                    {errors.km?.message}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <label>Accessories</label>
                  <Select
                    placeholder="Search Accessories..."
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={accrList}
                    isClearable={true}
                    formatOptionLabel={(item) => (
                      <div className="select-option">
                        <div className="details">
                          <h6>{item.label}</h6>
                          <p>{IndianRupee.format(item.price)}</p>
                        </div>
                      </div>
                    )}
                    {...restHeadphoneStorageField}
                    onChange={(option) =>
                      headphoneOnChange(option ? option.value : option)
                    }
                    value={
                      headphoneValue
                        ? accrList.find((x) => x.value === headphoneValue)
                        : headphoneValue
                    }
                  />
                  <div className="invalid-feedback">
                    {errors.headphone?.message}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div className="ip-grp">
                  <label>Operating System</label>
                  <Select
                    placeholder="Search Operating System..."
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={osList}
                    isClearable={true}
                    formatOptionLabel={(item) => (
                      <div className="select-option">
                        <div className="details">
                          <h6>{item.label}</h6>
                          <p>{IndianRupee.format(item.price)}</p>
                        </div>
                      </div>
                    )}
                    {...restUPSStorageField}
                    onChange={(option) =>
                      upsOnChange(option ? option.value : option)
                    }
                    value={
                      upsValue
                        ? osList.find((x) => x.value === upsValue)
                        : upsValue
                    }
                  />
                  <div className="invalid-feedback">
                    {errors.ups?.message}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <h6 className="ttl">Personal Information</h6>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                <div className="ip-grp">
                  <label>Full name</label>
                  <input
                    {...register("full_name")}
                    className="text-ip"
                    type="text"
                    placeholder="Ex: Nitin Bhardwaj"
                  />
                  <div className="invalid-feedback">
                    {errors.full_name?.message}
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                <div className="ip-grp">
                  <label>WhatsApp Number</label>
                  <input
                    {...register("phone_number")}
                    className="text-ip"
                    type="text"
                    placeholder="Enter your WhatsApp no. here..."
                  />
                  <div className="invalid-feedback">
                    {errors.phone_number?.message}
                  </div>
                  <small className="whatsapp-text">
                    <svg
                      role="img"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
                    </svg>
                    Please provide exact WhatsApp number. We will send you a
                    whatsapp message with the quotation!
                  </small>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                <div className="ip-grp">
                  <label>Email</label>
                  <input
                    {...register("email")}
                    className="text-ip"
                    type="text"
                    placeholder="Enter your email..."
                  />
                  <div className="invalid-feedback">
                    {errors.email?.message}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 btn-wp">
                <button type="submit" className="btn btn-primary">
                  Get Quotation & Pay
                </button>
              </div>
            </form>
          </div>

          {quotation ? (
            <div className="quotation-builder-second-phase">
              <h3>{quotation.full_name}, here's your finalised Quotation</h3>
              <div className="row">
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={plt_ico} alt="ico" />
                    </span>

                    <div className="dtl img">
                      {quotation.platform === "intel" ? (
                        <img src={intel} alt="Intel" />
                      ) : (
                        <img src={amd} alt="AMD" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={cpu_ico} alt="ico" />
                    </span>

                    <div className="dtl">
                      <p className="name">{quotation.cpu.label}</p>
                      <p className="pp">
                        {IndianRupee.format(quotation.cpu.price)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={mobo_ico} alt="ico" />
                    </span>

                    <div className="dtl">
                      <p className="name">{quotation.motherboard.label}</p>
                      <p className="pp">
                        {IndianRupee.format(quotation.motherboard.price)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={ram_ico} alt="ico" />
                    </span>

                    <div className="dtl">
                      <p className="name">
                        {quotation.ram.label} X {quotation.ram_qty}
                      </p>
                      <p className="pp">
                        {IndianRupee.format(
                          quotation.ram.price * quotation.ram_qty
                        )}{" "}
                        {quotation.ram_qty > 1
                          ? `(${IndianRupee.format(quotation.ram.price)} for 1)`
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={gpu_ico} alt="ico" />
                    </span>

                    <div className="dtl">
                      <p className="name">{quotation.graphicsCard.label}</p>
                      <p className="pp">
                        {IndianRupee.format(quotation.graphicsCard.price)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={ssd_ico_2} alt="ico" />
                    </span>

                    <div className="dtl">
                      <p className="name">
                        {quotation.primaryStorage.label} X{" "}
                        {quotation.primaryStorage_qty}
                      </p>
                      <p className="pp">
                        {IndianRupee.format(
                          quotation.primaryStorage.price *
                            quotation.primaryStorage_qty
                        )}{" "}
                        {quotation.primaryStorage_qty > 1
                          ? `(${IndianRupee.format(
                              quotation.primaryStorage.price
                            )} for 1)`
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={ssd_ico} alt="ico" />
                    </span>

                    <div className="dtl">
                      <p className="name">{quotation.secondaryStorage.label}</p>
                      <p className="pp">
                        {IndianRupee.format(quotation.secondaryStorage.price)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={case_ico} alt="ico" />
                    </span>

                    <div className="dtl">
                      <p className="name">{quotation.case.label}</p>
                      <p className="pp">
                        {IndianRupee.format(quotation.case.price)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={psu_ico} alt="ico" />
                    </span>

                    <div className="dtl">
                      <p className="name">{quotation.psu.label}</p>
                      <p className="pp">
                        {IndianRupee.format(quotation.psu.price)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={cooler_ico} alt="ico" />
                    </span>

                    <div className="dtl">
                      <p className="name">{quotation.cpuCooler.label}</p>
                      <p className="pp">
                        {IndianRupee.format(quotation.cpuCooler.price)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <h3>Add ons</h3>
              <div className="row">
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={mn_ico} alt="ico" />
                    </span>
                    <div className="dtl">
                      <p className="name">
                        {quotation.monitor.label} X {quotation.monitor_qty}
                      </p>
                      <p className="pp">
                        {IndianRupee.format(
                          quotation.monitor.price * quotation.monitor_qty
                        )}{" "}
                        {quotation.monitor_qty > 1
                          ? `(${IndianRupee.format(
                              quotation.monitor.price
                            )} for 1)`
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={km_ico} alt="ico" />
                    </span>

                    <div className="dtl">
                      <p className="name">{quotation.km.label}</p>
                      <p className="pp">
                        {IndianRupee.format(quotation.km.price)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={hd_ico} alt="ico" />
                    </span>

                    <div className="dtl">
                      <p className="name">{quotation.headphone.label}</p>
                      <p className="pp">
                        {IndianRupee.format(quotation.headphone.price)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                  <div className="selected-component">
                    <span className="ico">
                      <img src={ups_ico} alt="ico" />
                    </span>

                    <div className="dtl">
                      <p className="name">{quotation.ups.label}</p>
                      <p className="pp">
                        {IndianRupee.format(quotation.ups.price)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-fld">
                <h3 className="no-margin grand-total">
                  Grand Total:{" "}
                  <span>{IndianRupee.format(quotation.total)}</span>
                </h3>
                {/* <button className="btn btn-secondary-outline">
                  <svg
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
                  </svg>

                  Send on WhatsApp
                </button> */}

                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    createOrder();
                  }}
                >
                  Pay 10% Advance & Book
                </button>
                {/* <ReactToPrint
                  trigger={() => {
                    return (
                      <button className="btn btn-secondary qtn">
                        Print Quotation
                      </button>
                    );
                  }}
                  content={() => componentRef.current}
                /> */}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {quotation ? (
        <div
          className="printable-area"
          ref={componentRef}
          style={{ padding: "21px", width: "100%", background: "#ffffff" }}
        >
          <div
            className="header"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "32px",
            }}
          >
            <div className="img" style={{ height: "68px", width: "auto" }}>
              <img
                src={logo_blue}
                alt="logo_blue"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <small>
              S.C.O. 1112-1113, 2nd floor, Sector 22B, Chandigarh, 160022, Phone
              number: +91-94652-22255
            </small>
          </div>
          <p style={{ textAlign: "left", color: "#000000" }}>
            Hey {quotation.full_name}, thank you so much for giving us the
            opportunity to submit our quotation for your PC Build, We, at this
            moment, present the detailed quotation in support of your request
            dated {quotation.date} at {quotation.time}:
          </p>
          <table style={{ width: "100%", margin: "16px 0", fontSize: "14px" }}>
            <thead>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                    fontWeight: "bold",
                  }}
                >
                  Componnet
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                    fontWeight: "bold",
                  }}
                >
                  Selected item
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                    fontWeight: "bold",
                  }}
                >
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Platform
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.platform}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  -
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  CPU
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.cpu.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.cpu.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Motherboard
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.motherboard.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.motherboard.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  RAM
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.ram.label} X {quotation.ram_qty}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.ram.price * quotation.ram_qty)}{" "}
                  {quotation.ram_qty > 1
                    ? `(${IndianRupee.format(quotation.ram.price)}X1)`
                    : ""}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  GPU
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.graphicsCard.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.graphicsCard.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  CPU Cooler
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.cpuCooler.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.cpuCooler.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Primary Storage
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.primaryStorage.label} X{" "}
                  {quotation.primaryStorage_qty}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(
                    quotation.primaryStorage.price *
                      quotation.primaryStorage_qty
                  )}{" "}
                  {quotation.primaryStorage_qty > 1
                    ? `(${IndianRupee.format(
                        quotation.primaryStorage.price
                      )}X1)`
                    : ""}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Secondary Storage
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.secondaryStorage.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.secondaryStorage.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Cabinet
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.case.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.case.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Power Supply
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.psu.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.psu.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                    fontWeight: "bold",
                  }}
                  colSpan={3}
                >
                  Add ons
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Monitor
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.monitor.label} X {quotation.monitor_qty}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(
                    quotation.monitor.price * quotation.monitor_qty
                  )}{" "}
                  {quotation.monitor_qty > 1
                    ? `(${IndianRupee.format(quotation.monitor.price)}X1)`
                    : ""}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Keyboard & Mouse
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.km.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.km.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Accessories
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.headphone.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.headphone.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  UPS
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.ups.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.ups.price)}
                </th>
              </tr>
              <tr>
                <th
                  colSpan={2}
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "right",
                    padding: "4px 8px",
                    fontWeight: "bold",
                  }}
                >
                  Grand Total
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                    fontWeight: "bold",
                  }}
                >
                  {IndianRupee.format(quotation.total)}
                </th>
              </tr>
            </tbody>
          </table>

          <small style={{ marginTop: "48px" }}>
            ***This is a digitally generated quotation thus signature is not
            needed. However the prices mentioned in above quotation could be
            changed at anytime. and this quotation won't be valid after 3 days
            of generation...
          </small>
        </div>
      ) : (
        <></>
      )}

      {quotation ? (
        <div
          className="printable-area-download"
          id="html-template"
          ref={componentRef}
          style={{ padding: "21px", width: "100%", background: "#ffffff" }}
        >
          <div
            className="header"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginBottom: "24px",
            }}
          >
            <div
              className="img"
              style={{ height: "98px", width: "98px", marginRight: "32px" }}
            >
              <img
                src={logo_blue}
                alt="logo_blue"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <p
              style={{
                fontSize: "14px",
                textAlign: "left",
                color: "#121212",
                marginTop: "18px",
              }}
            >
              S.C.O. 1112-1113, 2nd floor,
              <br />
              Sector 22B, Chandigarh, 160022,
              <br />
              Phone number: +91-94652-22255
            </p>
          </div>
          <p style={{ textAlign: "left", color: "#000000" }}>
            Hey {quotation.full_name}, thank you so much for giving us the
            opportunity to submit our quotation for your PC Build, We, at this
            moment, present the detailed quotation in support of your request
            dated {quotation.date} at {quotation.time}:
          </p>
          <table style={{ width: "100%", margin: "16px 0", fontSize: "14px" }}>
            <thead>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                    fontWeight: "bold",
                  }}
                >
                  Componnet
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                    fontWeight: "bold",
                  }}
                >
                  Selected item
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                    fontWeight: "bold",
                  }}
                >
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Platform
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.platform}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  -
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  CPU
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.cpu.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.cpu.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Motherboard
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.motherboard.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.motherboard.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  RAM
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.ram.label} X {quotation.ram_qty}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.ram.price * quotation.ram_qty)}{" "}
                  {quotation.ram_qty > 1
                    ? `(${IndianRupee.format(quotation.ram.price)}X1)`
                    : ""}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  GPU
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.graphicsCard.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.graphicsCard.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  CPU Cooler
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.cpuCooler.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.cpuCooler.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Primary Storage
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.primaryStorage.label} X{" "}
                  {quotation.primaryStorage_qty}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(
                    quotation.primaryStorage.price *
                      quotation.primaryStorage_qty
                  )}{" "}
                  {quotation.primaryStorage_qty > 1
                    ? `(${IndianRupee.format(
                        quotation.primaryStorage.price
                      )}X1)`
                    : ""}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Secondary Storage
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.secondaryStorage.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.secondaryStorage.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Cabinet
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.case.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.case.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Power Supply
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.psu.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.psu.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                    fontWeight: "bold",
                  }}
                  colSpan={3}
                >
                  Add ons
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Monitor
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.monitor.label} X {quotation.monitor_qty}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(
                    quotation.monitor.price * quotation.monitor_qty
                  )}{" "}
                  {quotation.monitor_qty > 1
                    ? `(${IndianRupee.format(quotation.monitor.price)}X1)`
                    : ""}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Keyboard & Mouse
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.km.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.km.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  Accessories
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.headphone.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.headphone.price)}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  UPS
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {quotation.ups.label}
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                  }}
                >
                  {IndianRupee.format(quotation.ups.price)}
                </th>
              </tr>
              <tr>
                <th
                  colSpan={2}
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "right",
                    padding: "4px 8px",
                    fontWeight: "bold",
                  }}
                >
                  Grand Total
                </th>
                <th
                  style={{
                    border: "0.01rem solid #eeeeee",
                    textAlign: "left",
                    padding: "4px 8px",
                    fontWeight: "bold",
                  }}
                >
                  {IndianRupee.format(quotation.total)}
                </th>
              </tr>
            </tbody>
          </table>

          <small style={{ marginTop: "48px" }}>
            ***This is a digitally generated quotation thus signature is not
            needed. However the prices mentioned in above quotation could be
            changed at anytime. and this quotation won't be valid after 3 days
            of generation...
          </small>
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};

export default QuotationSection;
